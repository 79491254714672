import React from 'react';
import { useTheme } from '@mui/material';

import './footer.styles.scss';

export default function Footer() {
  const theme = useTheme();

  return (
    <footer style={{ '--primary-color': theme.palette.primary.main }}>
      <div>Content coming soon...</div>
    </footer>
  );
}
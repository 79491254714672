import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material';

import App from './App';
import { TabInfoProvider } from './contexts/tab-info.context';
import { GlobalProvider } from './contexts/global.context';

import './index.scss';

// Create theme object to more easily style components
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(12, 78, 12)',
    },
    secondary: {
      main: 'rgb(73, 150, 73)',
    },
    tertiary: {
      main: 'rgb(184, 223, 184)',
    },
  },
});

// Polyfill to allow react-markdown to work on Safari 15
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <TabInfoProvider>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </TabInfoProvider>
    </ThemeProvider>
  </React.StrictMode>
);
